import ProcessEnv = NodeJS.ProcessEnv;

export const isServer = () => typeof window === 'undefined';

export const isClient = () => typeof window !== 'undefined';

const getEnvironment = (
  key: keyof ProcessEnv,
): ProcessEnv[keyof ProcessEnv] => {
  const environment = process.env;

  if (!(key in environment)) {
    throw new Error(`Environment: ${key} is not defined in .env`);
  }

  return environment[key];
};

export const YANDEX_API_KEY = getEnvironment('NEXT_PUBLIC_YMAPS_KEY');
export const ZHILIBYLI_URL = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_URL'),
);
export const HOSTNAME = getEnvironment('NEXT_PUBLIC_HOSTNAME') || '';
export const ZHILIBYLI_PHONE = getEnvironment('NEXT_PUBLIC_ZHILIBYLI_PHONE');
export const HOTELIERS_KNOWLEDGE_BASE = getEnvironment(
  'NEXT_PUBLIC_HOTELIERS_KNOWLEDGE_BASE',
);
export const ZHILIBYLI_EMAIL = getEnvironment('NEXT_PUBLIC_ZHILIBYLI_EMAIL');
export const ZHILIBYLI_WHATSAPP = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_WHATSAPP'),
);
export const ZHILIBYLI_HOTELIERS_URL = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_HOTELIERS_URL'),
);
export const ZHILIBYLI_SUPPORT = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_SUPPORT'),
);
export const CONNECT_TRAVELLINE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_CONNECT_TRAVELLINE'),
);
export const CONNECT_REALTY_CALENDAR = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_CONNECT_REALTY_CALENDAR'),
);
export const CONNECT_BNOVO = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_CONNECT_BNOVO'),
);
export const HOW_TO_BOOK_ARTICLE = String(
  getEnvironment('NEXT_PUBLIC_HOW_TO_BOOK_ARTICLE'),
);
export const INFO_FOOTER = String(getEnvironment('NEXT_PUBLIC_INFO_FOOTER'));
export const ZHILIBYLI_TELEGRAM = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_TELEGRAM'),
);
export const ZHILIBYLI_MOBILE_APPS = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_MOBILE_APPS'),
);
export const ZHILIBYLI_OFFER = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_OFFER'),
);
export const KNOWLEDGE_BASE = String(
  getEnvironment('NEXT_PUBLIC_KNOWLEDGE_BASE'),
);
export const HOTELIERS_AUTH = String(
  getEnvironment('NEXT_PUBLIC_HOTELIERS_AUTH'),
);
export const HOTELIERS_REGISTRATION = String(
  getEnvironment('NEXT_PUBLIC_HOTELIERS_REGISTRATION'),
);
export const VERIFICATION_ADVANTAGES = String(
  getEnvironment('NEXT_PUBLIC_VERIFICATION_ADVANTAGES'),
);
export const YOUTUBE = String(getEnvironment('NEXT_PUBLIC_YOUTUBE'));
export const VK = String(getEnvironment('NEXT_PUBLIC_VK'));
export const TG = String(getEnvironment('NEXT_PUBLIC_TG'));
export const DZEN = String(getEnvironment('NEXT_PUBLIC_DZEN'));
export const ZHILIBYLI_ABOUT = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_ABOUT'),
);
export const ZHILIBYLI_POLITICS = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_POLITICS'),
);
export const ZHILIBYLI_RULES = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_RULES'),
);
export const ZHILIBYLI_SOFTWARE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_SOFTWARE'),
);
export const ZHILIBYLI_PUBLIC_OFFER_AGREEMENT = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_PUBLIC_OFFER_AGREEMENT'),
);
export const ZHILIBYLI_AFFILIATE = String(
  getEnvironment('NEXT_PUBLIC_ZHILIBYLI_AFFILIATE'),
);
export const MAX_TOTAL_FILE_SIZE = getEnvironment(
  'NEXT_PUBLIC_MAX_TOTAL_FILE_SIZE',
);
export const BITRIX_SIDE_BUTTON_SCRIPT = getEnvironment(
  'NEXT_PUBLIC_BITRIX_SIDE_BUTTON_SCRIPT',
);
export const YM_ID = getEnvironment('NEXT_PUBLIC_YM_ID');
export const GOOGLE_TAG = getEnvironment('NEXT_PUBLIC_GOOGLE_TAG');

export const ROISTAT_ID = getEnvironment('NEXT_PUBLIC_ROISTAT_ID');

export const HOTJAR_ID = getEnvironment('NEXT_PUBLIC_HOTJAR_ID');

export const MIN_BALANCE_WITHDRAW_AMOUNT = getEnvironment(
  'NEXT_PUBLIC_MIN_BALANCE_WITHDRAW_AMOUNT',
);

export const MAX_BALANCE_WITHDRAW_AMOUNT = getEnvironment(
  'NEXT_PUBLIC_MAX_BALANCE_WITHDRAW_AMOUNT',
);
export const MAX_DESCRIPTION_VALUE_LENGTH = Number(
  getEnvironment('NEXT_PUBLIC_MAX_DESCRIPTION_VALUE_LENGTH'),
);

export const DISABLE_PROFILE_CODE_LENGTH = Number(
  getEnvironment('NEXT_PUBLIC_DISABLE_PROFILE_CODE_LENGTH'),
);

export const APPLICATION_PUSH_UUID_LOCAL_STORAGE_KEY =
  getEnvironment('NEXT_PUBLIC_APPLICATION_PUSH_UUID_LOCAL_STORAGE_KEY') || '';

export const APPLICATION_OS_TYPE_LOCAL_STORAGE_KEY =
  getEnvironment('NEXT_PUBLIC_APPLICATION_OS_TYPE_LOCAL_STORAGE_KEY') || '';

export const APPLICATION_OS_VERSION_LOCAL_STORAGE_KEY =
  getEnvironment('NEXT_PUBLIC_APPLICATION_OS_VERSION_LOCAL_STORAGE_KEY') || '';

export const APPLICATION_PUSH_UUID_HEADER_NAME =
  getEnvironment('NEXT_PUBLIC_APPLICATION_PUSH_UUID_HEADER_NAME') || '';

export const APPLICATION_OS_TYPE_HEADER_NAME =
  getEnvironment('NEXT_PUBLIC_APPLICATION_OS_TYPE_HEADER_NAME') || '';

export const APPLICATION_OS_VERSION_HEADER_NAME =
  getEnvironment('NEXT_PUBLIC_APPLICATION_OS_VERSION_HEADER_NAME') || '';
