import {css, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {LabeledComponent} from 'library/components/labeled-component';
import {CircularLoader} from 'library/components/loader';
import {
  PatternInput,
  PatternInputProps,
} from 'library/components/pattern-input';
import {Select} from 'library/components/select';
import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Icon} from 'source/components/icon';
import {HotelContext} from 'source/context/hotel';
import {UtilitiesContext} from 'source/context/utilities';
import {useAppDispatch, useAppSelector} from 'source/store';
import {LegalFormEnum} from 'source/utilities/business';
import {showMessageWithDescription} from 'source/utilities/exceptions/business';
import {isFunction} from 'source/utilities/guards/types';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {isValidINN} from 'source/utilities/inn';
import {patterns} from 'source/utilities/patterns';
import {getBookingURLWithPath} from 'source/utilities/url';
import {MAX_ADDRESS_LENGTH} from 'slices/general_info/lib/constants';
import {
  INITIAL_REQUISITES_ID,
  initialRequisites,
  legalForms,
  personFields,
} from '../lib/constants';
import {getLegalForm, getValue, runFieldChecks} from '../lib/helpers';
import {InputKey} from '../lib/type';
import {handleCreateRequisites, handleVerifyRequisites} from '../network';
import {
  selectRequisitesFormInfo,
  setFile,
  setLegalForm,
  setLoading,
  setRequisites,
  setRequisitesExists,
  setRequisitesLabel,
} from '../store';
import {Invoice} from './invoice';

const VerifiedWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const VerifiedText = styled(Text)`
  color: ${({theme}) => theme.palette.success};
  margin-left: 8px;
`;

const CompanyTitle = styled(Text)`
  margin-bottom: 20px;
`;

const RequisitesTitle = styled(Text)`
  margin-bottom: 20px;
`;

const LegalForm = styled(LabeledComponent)`
  margin-bottom: 20px;
`;

const LegalFormSelect = styled(Select)`
  max-width: 440px;
`;

const ScenarioWrapper = styled.div`
  margin-bottom: 30px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`;

const Cancel = styled(Button)`
  width: 185px;
  height: 58px;
  margin-left: 20px;
  text-transform: uppercase;
  @media (max-width: 480px) {
    width: 100%;
    margin-left: unset;
    margin-bottom: 20px;
  }
`;

const Save = styled(Button)<{isLoading: boolean}>`
  width: 185px;
  height: 58px;
  ${({isLoading}) =>
    isLoading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
  text-transform: uppercase;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Title = styled(PatternInput)`
  margin-bottom: 20px;
  .cross-wrapper {
    max-width: 440px;
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Tin = styled(PatternInput)`
  margin-bottom: 20px;
  .cross-wrapper {
    max-width: 240px;
  }
  @media (max-width: 480px) {
    .cross-wrapper {
      max-width: 100%;
    }
  }
`;
// Tax Registration Reason Code
const TRRC = styled(PatternInput)`
  margin-bottom: 20px;
  .cross-wrapper {
    max-width: 240px;
  }
  @media (max-width: 480px) {
    .cross-wrapper {
      max-width: 100%;
    }
  }
`;

const BIC = styled(PatternInput)`
  .cross-wrapper {
    max-width: 240px;
  }
  @media (max-width: 480px) {
    .cross-wrapper {
      max-width: 100%;
    }
  }
`;
const EDO = styled(PatternInput)`
  margin: 15px 0 20px;
  .cross-wrapper {
    max-width: 440px;
    width: 100%;
  }
  @media (max-width: 480px) {
    .cross-wrapper {
      max-width: 100%;
    }
  }
`;
const RequisitesTitleEDO = styled(RequisitesTitle)`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const EDOAbbreviation = styled.span`
  text-decoration: underline;
  margin-bottom: 0;
`;

const CheckinAccount = styled(PatternInput)`
  margin-bottom: 20px;
  .cross-wrapper {
    max-width: 440px;
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Address = styled(PatternInput)`
  margin-bottom: 20px;
  .cross-wrapper {
    max-width: 440px;
    width: 100%;
  }
  @media (max-width: 480px) {
    .cross-wrapper {
      max-width: 100%;
    }
  }
`;

const PSRN = styled(PatternInput)`
  margin-bottom: 20px;
  .cross-wrapper {
    max-width: 240px;
  }
`;

const PSRNIE = styled(PatternInput)`
  margin-bottom: 20px;
  .cross-wrapper {
    max-width: 240px;
  }
  @media (max-width: 480px) {
    .cross-wrapper {
      max-width: 100%;
    }
  }
`;

const SupportText = styled(Text)`
  color: ${({theme}) => theme.palette.fontSecondary};
  cursor: pointer;
`;

export const RequisitesForm = forwardRef<HTMLParagraphElement>(
  ({}, titleRef) => {
    const {t} = useTranslation();
    const [hotel] = useContext(HotelContext);
    const [utilities] = useContext(UtilitiesContext);
    const mobile = useDeviceDetection('mobile');
    const theme = useTheme();
    const router = useRouter();
    const [force, setForce] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const dispatch = useAppDispatch();
    const {
      requisitesExists,
      requisites,
      legalForm,
      isRequisitesChosen,
      uploadingRequisites,
    } = useAppSelector(selectRequisitesFormInfo);

    const isLegalFormPerson = legalForm === LegalFormEnum.Person;
    const isSelfEmployed = legalForm === LegalFormEnum.SelfEmployed;

    const isFormDisabled =
      requisitesExists || isRequisitesChosen || uploadingRequisites;

    useEffect(() => {
      if (requisites.type.value !== legalForm) {
        dispatch(setLegalForm(requisites.type.value));
      }
      setDisabled(!runFieldChecks(requisites, legalForm));
    }, [requisites, legalForm]);

    const onRequisitesChange = (key: InputKey, value: string | number) => {
      if (key === 'address' && String(value).length > MAX_ADDRESS_LENGTH) {
        return;
      }
      dispatch(
        setRequisites({...requisites, id: INITIAL_REQUISITES_ID, [key]: value}),
      );
    };

    const onInputChange = (
      event: ChangeEvent<HTMLInputElement>,
      key: InputKey,
    ) => {
      return onRequisitesChange(key, getValue(event));
    };

    const onInputFocus = (event: FocusEvent<HTMLInputElement>, key: InputKey) =>
      key === 'account' &&
      onRequisitesChange(key, getValue(event).replace(/\s+/g, ''));

    const onInputBlur = (event: FocusEvent<HTMLInputElement>, key: InputKey) =>
      key === 'account' &&
      onRequisitesChange(
        key,
        getValue(event)
          .replace(/\s+/g, '')
          .replace(/(.{4})(?=\S)/g, '$1 '),
      );

    const handleFile = (file: Blob) => {
      dispatch(setFile(file));
      dispatch(setRequisitesExists(true));
      if (titleRef && !isFunction(titleRef) && titleRef.current) {
        titleRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
      showMessageWithDescription(
        t('requisites:info_saved'),
        t('requisites:info_saved_description'),
      );
      dispatch(setLoading(false));
    };

    const handleCreateNewOrganization = (event: FormEvent<HTMLFormElement>) => {
      if (!hotel || requisitesExists) {
        return;
      }

      handleCreateRequisites(
        hotel,
        {...requisites, account: requisites.account.replace(/\s+/g, '')},
        handleFile,
      )(event);
    };

    const handleVerifyOrganization = (event: FormEvent<HTMLFormElement>) => {
      if (!hotel || !requisites.id) {
        return;
      }

      handleVerifyRequisites(hotel, requisites.id, handleFile)(event);
    };

    const handleSubmit =
      requisites.id === INITIAL_REQUISITES_ID
        ? handleCreateNewOrganization
        : handleVerifyOrganization;

    const getInputProps = (
      key: InputKey,
      parentForce?: ControlStatus,
    ): Omit<PatternInputProps, 'value'> => {
      return {
        setValue: (value) => onRequisitesChange(key, value),
        onChange: (event) => onInputChange(event, key),
        onBlur: (event) => onInputBlur(event, key),
        onFocus: (event) => onInputFocus(event, key),
        gap: 10,
        required: Boolean(parentForce) ?? force,
        disabled: isFormDisabled,
        force: parentForce ?? (force ? 'required' : 'pristine'),
        color: theme.palette.fontSecondary,
      };
    };

    const cancelHandler = () => {
      dispatch(setRequisites(initialRequisites));
      dispatch(setRequisitesLabel(''));
    };

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        {utilities.windowWidth <= 900 ? <Invoice /> : null}
        {hotel?.verified && (
          <VerifiedWrapper>
            <Icon name="green-tick" width={20} height={20} />
            <VerifiedText size={mobile ? 'boldS' : 'boldM'}>
              {t('requisites:requisites_verified')}
            </VerifiedText>
          </VerifiedWrapper>
        )}
        <CompanyTitle size="S">{t('requisites:about_company')}</CompanyTitle>
        <LegalForm
          text={t('requisites:legal_form')}
          size="S"
          gap={10}
          color={theme.palette.fontSecondary}
        >
          <LegalFormSelect
            setValue={(option) => {
              const typedValue = option.value as LegalFormEnum;
              dispatch(
                setRequisites({
                  ...requisites,
                  id: INITIAL_REQUISITES_ID,
                  type: {
                    value: typedValue as Requisites['type']['value'],
                    title: getLegalForm(typedValue).label,
                  },
                }),
              );
              dispatch(setLegalForm(option.value));
            }}
            options={legalForms}
            disabled={isFormDisabled}
            value={getLegalForm(legalForm)}
          />
        </LegalForm>
        <ScenarioWrapper>
          <Title
            value={requisites?.name || ''}
            text={
              personFields.includes(legalForm)
                ? t('requisites:full_name')
                : t('requisites:name')
            }
            placeholder={
              personFields.includes(legalForm)
                ? t('requisites:enter_full_name')
                : t('requisites:enter_name')
            }
            {...getInputProps('name')}
          />
          <Address
            maxCount={MAX_ADDRESS_LENGTH}
            text={
              legalForm === LegalFormEnum.JuridicalPerson
                ? t('requisites:juri_address')
                : t('requisites:register_address')
            }
            value={requisites?.address || ''}
            placeholder={
              legalForm === LegalFormEnum.JuridicalPerson
                ? t('requisites:enter_juri_address')
                : t('requisites:enter_register_address')
            }
            {...getInputProps('address')}
          />
          <RequisitesTitle size="S">
            {t('requisites:other_requisites')}
          </RequisitesTitle>
          <CheckinAccount
            text={
              isLegalFormPerson
                ? t('requisites:account_2')
                : t('requisites:account')
            }
            placeholder={
              isLegalFormPerson
                ? t('requisites:enter_account_2')
                : t('requisites:enter_account')
            }
            value={requisites?.account || ''}
            patternId={isLegalFormPerson ? 'account_2' : 'account'}
            pattern={isLegalFormPerson ? patterns.account_2 : patterns.account}
            {...getInputProps('account')}
          />
          <Tin
            text={
              isLegalFormPerson || isSelfEmployed
                ? t('requisites:inn_2')
                : t('requisites:inn')
            }
            value={requisites?.inn || ''}
            placeholder={
              isLegalFormPerson || isSelfEmployed
                ? t('requisites:enter_inn_2')
                : t('requisites:enter_inn')
            }
            pattern={isValidINN}
            forcedMessage={t('components:incorrect_format')}
            {...getInputProps('inn')}
          />
          {legalForm === LegalFormEnum.JuridicalPerson ? (
            <TRRC
              value={requisites?.kpp || ''}
              placeholder={t('requisites:enter_kpp')}
              text={t('requisites:kpp')}
              patternId="kpp"
              pattern={patterns.kpp}
              {...getInputProps('kpp')}
            />
          ) : null}
          {legalForm === LegalFormEnum.JuridicalPerson ? (
            <PSRN
              value={requisites?.ogrn || ''}
              placeholder={t('requisites:enter_ogrn')}
              text={t('requisites:ogrn')}
              patternId="ogrn"
              pattern={patterns.ogrn}
              {...getInputProps('ogrn')}
            />
          ) : null}
          {legalForm === LegalFormEnum.IndividualEntrepreneur ? (
            <PSRNIE
              value={requisites?.ogrnip || ''}
              placeholder={t('requisites:enter_ogrnip')}
              text={t('requisites:ogrnip')}
              patternId="ogrnip"
              pattern={patterns.ogrnip}
              {...getInputProps('ogrnip')}
            />
          ) : null}
          {!isLegalFormPerson && (
            <BIC
              value={requisites?.bik || ''}
              text={t('requisites:bik')}
              placeholder={t('requisites:enter_bik')}
              patternId="bik"
              pattern={patterns.bik}
              {...getInputProps('bik')}
            />
          )}
        </ScenarioWrapper>
        <RequisitesTitleEDO size="S">
          {t('requisites:document_flow_title')}
          <Tooltip
            on="hover"
            position="bottom left"
            trigger={
              <EDOAbbreviation>
                {t('requisites:abbreviation_flow')}
              </EDOAbbreviation>
            }
          >
            {t('requisites:abbreviation_description')}
          </Tooltip>
          {t('requisites:document_flow_title_2')}
        </RequisitesTitleEDO>
        <EDO
          value={requisites?.document_flow || ''}
          placeholder={t('requisites:document_flow')}
          {...getInputProps('document_flow')}
        />
        <ActionWrapper>
          {requisitesExists ? (
            <SupportText
              size="S"
              onClick={() => router.push(getBookingURLWithPath('/support'))}
            >
              {t('requisites:support')}
            </SupportText>
          ) : (
            <Save
              isLoading={uploadingRequisites}
              onClick={(event) => {
                if (disabled) {
                  console.log('isDisabled');
                  event.preventDefault();
                  setForce(true);
                }
              }}
              type="submit"
            >
              {uploadingRequisites ? (
                <CircularLoader size={40} />
              ) : (
                t('requisites:save')
              )}
            </Save>
          )}
          {isRequisitesChosen && !requisitesExists && !uploadingRequisites ? (
            <Cancel buttonType="secondary" onClick={cancelHandler}>
              {t('requisites:cancel')}
            </Cancel>
          ) : null}
        </ActionWrapper>
      </form>
    );
  },
);

RequisitesForm.displayName = 'RequisitesForm';
