import styled from '@emotion/styled';
import {Link} from 'source/components/link';
import {Icon} from 'source/components/icon';
import {VK, TG, YOUTUBE, DZEN} from 'source/utilities/guards/environment';

const Wrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({theme}) => theme.palette.border_6};
  border-radius: 12px;

  svg {
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.primaryAccent};
    }
  }
`;

export const SocialLinks = () => {
  return (
    <Wrapper>
      <LinkWrapper>
        <StyledLink href={VK} target="_blank" rel="nofollow">
          <Icon name="vk" width={21} height={12} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink href={TG} target="_blank" rel="nofollow">
          <Icon name="footer-telegram" width={22} height={22} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink href={YOUTUBE} target="_blank" rel="nofollow">
          <Icon name="youtube" width={22} height={16} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink href={DZEN} target="_blank" rel="nofollow">
          <Icon name="dzen" width={22} height={16} />
        </StyledLink>
      </LinkWrapper>
    </Wrapper>
  );
};
