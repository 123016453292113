import {FC, useContext} from 'react';

import Link from 'next/link';
import styled from '@emotion/styled';

import {UtilitiesContext} from 'source/context/utilities';

import {Icon} from 'source/components/icon';
import {Routes} from 'source/utilities/network/url';

const Figure = styled.figure`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  @media screen and (min-width: 639px) {
    gap: 12px;
  }
`;

const Figcaption = styled.figcaption`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  width?: number;
  height?: number;
}

const Logo: FC<Props> = ({width, height}) => {
  const [utilities] = useContext(UtilitiesContext);

  return (
    <Link href={Routes.home}>
      <Figure>
        <Icon name="logo-icon" width={width || 45} height={height || 45} />
        <Figcaption>
          {!utilities.mobile && !utilities.tablet && (
            <Icon name="logo-title" width={120} height={20} />
          )}
          {!utilities.mobile && (
            <Icon name="logo-subtitle" width={120} height={12} />
          )}
        </Figcaption>
      </Figure>
    </Link>
  );
};

export default Logo;
