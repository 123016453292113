import useTranslation from 'next-translate/useTranslation';
import {Link} from 'source/components/link';
import {
  HOTELIERS_AUTH,
  HOTELIERS_REGISTRATION,
  KNOWLEDGE_BASE,
  VERIFICATION_ADVANTAGES,
  ZHILIBYLI_OFFER,
} from 'source/utilities/guards/environment';
import {
  LinkList,
  LinkListItem,
  LinkListTitle,
  LinkListWrapper,
  StyledText,
  StyledTextBold,
} from '../link-list';

export const HoteliersColumn = () => {
  const {t} = useTranslation('footer');

  return (
    <LinkListWrapper>
      <LinkListTitle size="boldM">{t('hoteliers')}</LinkListTitle>
      <LinkList>
        <LinkListItem>
          <Link href={KNOWLEDGE_BASE}>
            <StyledTextBold size="boldS"> {t('help_hoteliers')}</StyledTextBold>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={HOTELIERS_REGISTRATION}>
            <StyledTextBold size="boldS">
              {' '}
              {t('own_hotel_registration')}
            </StyledTextBold>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={HOTELIERS_AUTH}>
            <StyledText size="S"> {t('personal_account')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={VERIFICATION_ADVANTAGES}>
            <StyledText size="S"> {t('verification_advantages')}</StyledText>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ZHILIBYLI_OFFER}>
            <StyledText size="S"> {t('offer')}</StyledText>
          </Link>
        </LinkListItem>
      </LinkList>
    </LinkListWrapper>
  );
};
