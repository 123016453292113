import styled from '@emotion/styled';
import {FC, useContext} from 'react';
import useTranslation from 'next-translate/useTranslation';
import {NavigationLink} from 'library/components/navigation-link';
import {Icon} from 'source/components/icon';
import {UtilitiesContext} from 'source/context/utilities';
import {Logo} from '../../ui';
import {SupportPopup} from '../../popups/support';
import {
  MAXIMUM_WIDTH_LIMIT,
  MAXIMUM_WIDTH_MOBILE_ICONS,
  MOBILE_WIDTH_FOR_ICONS,
} from '../../lib/constants';

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 24px;

  @media (max-width: 460px) {
    gap: 13px;
  }
`;

const PopupButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 460px) {
    gap: 13px;
  }
`;

const StyledNavigationLink = styled(NavigationLink)`
  padding: 0 32px;
  height: 45px;
  background: ${({theme}) => theme.palette.fontPrimary};
  border: 1px solid ${({theme}) => theme.palette.fontPrimary};
  border-radius: 8px;
  transition: background 0.5s;

  p {
    color: ${({theme}) => theme.palette.fontAccent};
  }

  &:hover {
    background: ${({theme}) => theme.palette.extranetButtonHover};
    p {
      color: ${({theme}) => theme.palette.fontAccent};
    }
  }

  @media (max-width: 460px) {
    height: 32px;
    p {
      font-size: 11px;
      line-height: 14px;
    }
  }
  @media (max-width: 375px) {
    padding: 0 16px;
  }
`;

const StyledLoginNavigationLink = styled(StyledNavigationLink)`
  background: ${({theme}) => theme.palette.defaultBackground};
  border: 1px solid ${({theme}) => theme.palette.fontPrimary};

  p {
    color: ${({theme}) => theme.palette.fontPrimary};
  }

  &:hover {
    background: ${({theme}) => theme.palette.backgroundExtranet_3};
    p {
      color: ${({theme}) => theme.palette.fontPrimary};
    }
  }

  @media (max-width: 690px) {
    padding: 0 8px;
  }

  @media (max-width: 460px) {
    width: 32px;
    padding: 0 4px;
    svg {
      width: 21px;
      height: 21px;
    }
  }
`;

const AdaptiveSupportPopup: FC<{windowWidth: number}> = ({windowWidth}) => {
  return (
    <>
      {windowWidth < MOBILE_WIDTH_FOR_ICONS ? (
        <SupportPopup
          questionWidth={MAXIMUM_WIDTH_MOBILE_ICONS}
          questionHeight={MAXIMUM_WIDTH_MOBILE_ICONS}
        />
      ) : (
        <SupportPopup />
      )}
    </>
  );
};

const NavigationLogo: FC<{windowWidth: number}> = ({windowWidth}) => {
  return (
    <>
      {windowWidth < MOBILE_WIDTH_FOR_ICONS ? (
        <Logo
          width={MAXIMUM_WIDTH_MOBILE_ICONS}
          height={MAXIMUM_WIDTH_MOBILE_ICONS}
        />
      ) : (
        <Logo />
      )}
    </>
  );
};

const NavigationAncors: FC<{windowWidth: number}> = ({windowWidth}) => {
  const {t} = useTranslation('routing');
  return (
    <>
      <StyledNavigationLink href="/registration">
        {windowWidth < MAXIMUM_WIDTH_LIMIT
          ? t('registration_2')
          : t('registration')}
      </StyledNavigationLink>
      <StyledLoginNavigationLink href="/authorization">
        {windowWidth < MAXIMUM_WIDTH_LIMIT ? (
          <Icon name="login" width={26} height={29} />
        ) : (
          t('authorization')
        )}
      </StyledLoginNavigationLink>
    </>
  );
};

export const UnauthorizedScenario: FC<{mobile: boolean; tablet: boolean}> = ({
  mobile,
  tablet,
}) => {
  const [utilities] = useContext(UtilitiesContext);
  if (tablet || mobile) {
    return (
      <StyledContent>
        <NavigationLogo windowWidth={utilities.windowWidth} />
        <PopupButtonsWrapper>
          <Navigation>
            <NavigationAncors windowWidth={utilities.windowWidth} />
          </Navigation>
          <AdaptiveSupportPopup windowWidth={utilities.windowWidth} />
        </PopupButtonsWrapper>
      </StyledContent>
    );
  }

  return (
    <StyledContent>
      <NavigationLogo windowWidth={utilities.windowWidth} />
      <PopupButtonsWrapper>
        <AdaptiveSupportPopup windowWidth={utilities.windowWidth} />
        <Navigation>
          <NavigationAncors windowWidth={utilities.windowWidth} />
        </Navigation>
      </PopupButtonsWrapper>
    </StyledContent>
  );
};
