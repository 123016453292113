import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {FC} from 'react';
import {InformationColumn} from './ui/information-column';
import {HoteliersColumn} from './ui/hoteliers-bolumn';
import {MobileAppColumn} from './ui/mobile-app-column';
import {PaymentMethods} from './ui/payment-methods';
import {StyledText} from './ui/link-list';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1204px;
  width: 100%;
  padding: 0px 32px;

  @media (max-width: 1440px) {
    max-width: 1024px;
  }

  @media (max-width: 1024px) {
    padding: 0px 32px;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.palette.border_6};
`;

const TopDivider = styled(HorizontalDivider)`
  margin-bottom: 60px;

  @media (max-width: 640px) {
    margin-bottom: 20px;
  }
`;

const BottomDivider = styled(HorizontalDivider)`
  margin-top: 60px;

  @media (max-width: 960px) {
    margin-top: 26px;
  }
`;

const ColumnsWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 270px 270px 348px;
  gap: 5px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 32px 16px;

    div:nth-child(3) {
      grid-column: 1 / -1;
    }
  }
  @media (max-width: 450px) {
    gap: 21px 16px;
  }
`;

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
`;

export const Footer: FC = () => {
  const {t} = useTranslation('routing');

  return (
    <StyledFooter>
      <TopDivider />
      <Container>
        <ColumnsWrapper>
          <InformationColumn />
          <HoteliersColumn />
          <MobileAppColumn />
        </ColumnsWrapper>
      </Container>
      <BottomDivider />
      <Container>
        <CopyrightWrapper>
          <StyledText size="S">
            {t('copyright', {year: new Date().getFullYear()})}
          </StyledText>
          <PaymentMethods />
        </CopyrightWrapper>
      </Container>
    </StyledFooter>
  );
};
