import styled from '@emotion/styled';
import {Icon} from 'source/components/icon';
import {Link} from 'source/components/link';
import {ZHILIBYLI_MOBILE_APPS} from 'source/utilities/guards/environment';
import {DEFAULT_ANDROID, DEFAULT_IOS} from '../../lib/constants';

const Wrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 32px;

  @media (max-width: 960px) {
    max-width: 350px;
  }

  @media (max-width: 450px) {
    gap: 20px 13px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  height: 48px;

  @media (max-width: 450px) {
    width: 140px;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({theme}) => theme.palette.border_6};
  border-radius: 12px;
  transition: border-color 0.3s ease-in-out;

  &:hover {
    border-color: ${({theme}) => theme.palette.borderSecondary};
  }

  @media (max-width: 450px) {
    padding: 15px 10px;
  }
`;

export const MobileStoreLinks = () => {
  const ANDRIOD_HREF = `${ZHILIBYLI_MOBILE_APPS}#${DEFAULT_ANDROID}`;
  const IOS_HREF = `${ZHILIBYLI_MOBILE_APPS}#${DEFAULT_IOS}`;

  return (
    <Wrapper>
      <LinkWrapper>
        <StyledLink href={ANDRIOD_HREF}>
          <Icon name="google-play" width={102} height={23} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink href={IOS_HREF}>
          <Icon name="app-store" width={95} height={24} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink href={ANDRIOD_HREF}>
          <Icon name="ru-store" width={100} height={30} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper>
        <StyledLink href={ANDRIOD_HREF}>
          <Icon name="app-gallery" width={115} height={20} />
        </StyledLink>
      </LinkWrapper>
    </Wrapper>
  );
};
