import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {Icon} from 'source/components/icon';
import {Link} from 'source/components/link';
import {ZHILIBYLI_MOBILE_APPS} from 'source/utilities/guards/environment';
import {LinkListTitle} from '../link-list';
import {MobileStoreLinks} from '../mobile-store-links';
import {SocialLinks} from '../social-links';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

const StyledLink = styled(Link)`
  svg {
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.primaryAccent};
    }
  }
`;

export const MobileAppColumn = () => {
  const {t} = useTranslation('footer');

  return (
    <Wrapper>
      <StyledLink href={ZHILIBYLI_MOBILE_APPS}>
        <LinkListTitle size="boldM">
          {t('mobile_application')}
          <StyledIcon name="arrow-small" width={24} height={24} />
        </LinkListTitle>
      </StyledLink>
      <MobileStoreLinks />
      <SocialLinks />
    </Wrapper>
  );
};
