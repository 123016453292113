import {FC} from 'react';

export const Question: FC<IconProps> = ({style, className, onClick, color}) => (
  <svg
    onClick={onClick}
    style={style}
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="47"
      height="47"
      rx="7.5"
      stroke={color || '#9292AA'}
    />
    <path
      d="M23.072 28.16C23.072 27.328 23.2106 26.6027 23.488 25.984C23.7653 25.3653 24.1066 24.8107 24.512 24.32C24.9386 23.8293 25.3973 23.3813 25.888 22.976C26.3786 22.5493 26.8373 22.1333 27.264 21.728C27.6906 21.3013 28.032 20.8533 28.288 20.384C28.5653 19.9147 28.704 19.3707 28.704 18.752C28.704 17.728 28.2986 16.896 27.488 16.256C26.6773 15.616 25.5573 15.296 24.128 15.296C22.848 15.296 21.7386 15.5093 20.8 15.936C19.8613 16.3627 19.0613 16.992 18.4 17.824L16.064 16.16C16.96 15.0293 18.1013 14.1547 19.488 13.536C20.8746 12.896 22.496 12.576 24.352 12.576C25.888 12.576 27.2213 12.8107 28.352 13.28C29.4826 13.728 30.3573 14.3787 30.976 15.232C31.616 16.064 31.936 17.0773 31.936 18.272C31.936 19.168 31.7973 19.936 31.52 20.576C31.2426 21.216 30.8906 21.7813 30.464 22.272C30.0373 22.7627 29.5786 23.2213 29.088 23.648C28.6186 24.0747 28.1706 24.5013 27.744 24.928C27.3173 25.3547 26.9653 25.824 26.688 26.336C26.4106 26.848 26.272 27.456 26.272 28.16H23.072ZM24.704 35.424C24.1066 35.424 23.6053 35.2213 23.2 34.816C22.816 34.4107 22.624 33.9307 22.624 33.376C22.624 32.7787 22.816 32.288 23.2 31.904C23.6053 31.52 24.1066 31.328 24.704 31.328C25.3013 31.328 25.792 31.52 26.176 31.904C26.56 32.288 26.752 32.7787 26.752 33.376C26.752 33.9307 26.56 34.4107 26.176 34.816C25.792 35.2213 25.3013 35.424 24.704 35.424Z"
      fill={color || '#9292AA'}
    />
  </svg>
);
