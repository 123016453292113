import styled from '@emotion/styled';
import {Headline} from 'library/components/headline';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {FC, useContext, useEffect, useMemo} from 'react';
import {
  reset,
  selectPhotos,
  setPhotos,
  sortImages,
} from 'slices/room-photos/store';
import {Photos} from 'source/components/photos-dashboard/photos';
import {BackButton} from 'library/components/back-button';
import {setRoomMainImage, setRooms} from 'slices/rooms/store';
import {HotelContext} from 'source/context/hotel';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {parseNumberQueryParameter} from 'source/utilities/network/router';
import {showSuccessMessage} from 'source/utilities/exceptions/business';
import {MAX_ROOM_IMAGES, MIN_ROOM_IMAGES} from './lib/constants';
import {
  isMainImage,
  onDeleteSelectedComplete,
  onFileDeleteComplete,
  onFilesUpdate,
  onImageDelete,
  onImageUpload,
  onMoveImages,
  onRemoveImage,
  onReorderImages,
  onSelect,
  onSelectImage,
  onUnselect,
  transformRoomImages,
} from './lib/helpers';
import {changeRoomMainImage, deleteImages} from './network';

const Wrapper = styled.div``;

const PhotoWrapper = styled.div`
  margin-top: 10px;
`;

const Back = styled(BackButton)`
  margin-bottom: 15px;
`;

interface Props {
  images?: RoomImages;
  rooms?: HotelRoomsShorts;
}

const Content: FC<Props> = ({images, rooms}) => {
  const {query, back} = useRouter();

  const [hotel] = useContext(HotelContext);
  const {t} = useTranslation('photos');

  const mobile = useDeviceDetection('mobile');

  const dispatch = useAppDispatch();
  const photosState = useAppSelector(selectPhotos);
  const parsedRoomId = useMemo(
    () => parseNumberQueryParameter(query.room_slug),
    [query.room_slug],
  );

  const roomName = useMemo(
    () =>
      rooms && rooms.length > 0 && parsedRoomId
        ? rooms.find((room) => room.id === parsedRoomId)?.name
        : '',
    [parsedRoomId],
  );

  useEffect(() => {
    dispatch(reset());

    if (images) {
      dispatch(setPhotos(transformRoomImages(images)));
      dispatch(sortImages());
    }
    if (rooms) {
      dispatch(setRooms(rooms));
    }
  }, [rooms, images, hotel?.id]);

  return (
    <>
      <Back action={back} />
      <Headline level={mobile ? 'M' : 'XL'}>
        {t('headline_rooms', {
          count: photosState.images?.length || 0,
          roomName,
          maxCount: MAX_ROOM_IMAGES,
        })}
      </Headline>

      <Wrapper>
        <PhotoWrapper>
          <Photos
            images={photosState.images || []}
            selectedImages={photosState.selectedImages || []}
            minPhotos={MIN_ROOM_IMAGES}
            maxPhotos={MAX_ROOM_IMAGES}
            roomId={parsedRoomId}
            onDeleteComplete={onFileDeleteComplete}
            onDeleteSelectedComplete={onDeleteSelectedComplete}
            onRemoveImage={onRemoveImage}
            onMoveImages={onMoveImages}
            onSelect={onSelect}
            onSelectImage={onSelectImage}
            onUnselect={onUnselect}
            isMainImage={isMainImage}
            onFileDeleteComplete={onFileDeleteComplete}
            onFilesUpdate={onFilesUpdate}
            onImageUpload={onImageUpload}
            onReorderImages={(
              activeId,
              preHotelImageId,
              images,
              activeIndex,
              overIndex,
            ) =>
              onReorderImages(
                parsedRoomId,
                activeId,
                preHotelImageId,
                images,
                activeIndex,
                overIndex,
                hotel,
              )
            }
            onMainImageChange={async (imageId, url) =>
              changeRoomMainImage(
                parsedRoomId,
                imageId,
                () => {
                  showSuccessMessage(t('main_image_successfull_change'));
                  dispatch(
                    setRoomMainImage({roomId: parsedRoomId, image: url}),
                  );
                },
                hotel,
              )
            }
            onFileDelete={(imageId, onComplete, setLoading) =>
              onImageDelete(
                imageId,
                parsedRoomId,
                onComplete,
                setLoading,
                hotel,
              )
            }
            onDeleteImage={(imageId, onComplete, setLoading) =>
              onImageDelete(
                imageId,
                parsedRoomId,
                onComplete,
                setLoading,
                hotel,
              )
            }
            onDeleteSelected={(selectedImages, onComplete, setLoading) =>
              deleteImages(
                parsedRoomId,
                selectedImages,
                onComplete,
                setLoading,
                hotel,
              )
            }
          />
        </PhotoWrapper>
      </Wrapper>
    </>
  );
};

export default Content;
