import styled from '@emotion/styled';
import {Question} from 'library/components/hint/question';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import useTranslation from 'next-translate/useTranslation';

import {Link} from 'source/components/link';
import {
  HOTELIERS_KNOWLEDGE_BASE,
  ZHILIBYLI_PHONE,
  isClient,
  ZHILIBYLI_EMAIL,
} from 'source/utilities/guards/environment'
import {DEFAULT_HREF} from 'source/utilities/constants/logic';
import {Bitrix} from 'source/services/bitrix';
import {Text} from 'library/components/text';
import {Button} from 'library/components/button';
import {ButtonLink} from 'library/components/button-link';
import {Icon} from 'source/components/icon';
import {palette} from 'source/utilities/global-style';
import {FC} from 'react';
import {MenuPopup} from '../ui';

const supportTriggerClassname = 'support-trigger';

const StyledMenuPopup = styled(MenuPopup)`
  &-content {
    padding: 0;
    width: 650px !important;

    @media (max-width: 700px) {
      width: calc(100% - 20px) !important;
    }
  }
`;

const Trigger = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledQuestion = styled(Question)<{width?: number; height?: number;}>`
  width: ${({width}) => `${width ?? 45}`}px;
  height: ${({height}) => `${height ?? 45}`}px;
`;

const ChatButton = styled(Button)`
  width: 175px;
  padding: 15px;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const KnowledgeBaseLink = styled(ButtonLink)`
  color: ${({theme}) => theme.palette.backgroundIconExtranet_2};
  background-color: ${({theme}) => theme.palette.defaultBackground};
  border: ${({theme}) => `1px solid ${theme.palette.backgroundIconExtranet_2}`};
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  width: 175px;
  height: 100%;

  @media (max-width: 550px) {
    width: 100%;
  }

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundIconExtranet_3};
    border-color: ${({theme}) => theme.palette.backgroundIconExtranet_3};
  }
`;

const StyledText = styled(Text)`
  font-family: ${({theme}) => theme.fontFamily.MONTSERRAT};
  font-size: 24px;
  line-height: 33px;

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 25px;
  }
`;

const Wrapper = styled.div`
  cursor: pointer;
  font-family: ${({theme}) => theme.fontFamily.MONTSERRAT};
  width: 100%;
  box-shadow: 0 12px 56px 0 ${({theme}) => theme.palette.backgroundBoxShadow};
  border: 1px solid ${({theme}) => theme.palette.extranetBorder};
  background: ${({theme}) => theme.palette.primaryBackground};
  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  transition: box-shadow 0.7s;
  display: flex;
  align-items: center;

  &:hover {
    box-shadow: ${({theme}) => theme.palette.extranetHoverBoxShadow};
  }
`;

const IconWrapper = styled.div`
  font-family: ${({theme}) => theme.fontFamily.MONTSERRAT};
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 8px;
  background: ${({theme}) => theme.palette.backgroundIconExtranet_1};
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 4px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const SupportText = styled(Text)`
  font-family: ${({theme}) => theme.fontFamily.MONTSERRAT};

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 19px;
  }
`;

const LinksWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const MainInfoWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.palette.border_6}`};
  padding: 32px;
  display: grid;
  gap: 32px;

  @media (max-width: 900px) {
    padding: 16px;
  }
`;

const Instructionswrapper = styled.div`
  display: grid;
  gap: 16px;
  padding: 32px;

  @media (max-width: 900px) {
    padding: 16px;
  }
`;

interface Props {
  questionWidth?: number;
  questionHeight?: number;
}

export const SupportPopup: FC<Props> = ({questionWidth, questionHeight}) => {
  const touch = useDeviceDetection('touch');
  const {t} = useTranslation('components');

  const handleStartChatClick = () => {
    if (isClient()) {
      const supportTrigger = document.querySelector<HTMLDivElement>(
        `.${supportTriggerClassname}`,
      );

      Bitrix.showBitrixLiveChat();

      if (supportTrigger) {
        supportTrigger.click();
      }
    }
  };

  return (
    <StyledMenuPopup
      trigger={
        <Trigger className={supportTriggerClassname}>
          <StyledQuestion width={questionWidth} height={questionHeight} />
        </Trigger>
      }
      closeOnDocumentClick
      arrow={false}
      offsetY={20}
      position="bottom right"
      modal={touch}
    >
      <ContentWrapper>
        <MainInfoWrapper>
          <div>
            <StyledText color={palette.fontExtranet} size="M">
              {t('support.title_1')}
            </StyledText>
            <StyledText color={palette.fontPrimary} size="M">
              {t('support.title_2')}
            </StyledText>
          </div>
          <LinksWrapper>
            <Link href={`tel:${ZHILIBYLI_PHONE}`}>
              <Wrapper>
                <IconWrapper>
                  <Icon name="phone-header" height={24} width={24} />
                </IconWrapper>
                <InfoWrapper>
                  <SupportText color={palette.fontExtranet} size="S">
                    {t('support.phone')}
                  </SupportText>
                  <SupportText color={palette.fontPrimary} size="S">
                    {ZHILIBYLI_PHONE}
                  </SupportText>
                </InfoWrapper>
              </Wrapper>
            </Link>
            <Link href={`mailto:${ZHILIBYLI_EMAIL}`}>
              <Wrapper>
                <IconWrapper>
                  <Icon name="mail-header" height={24} width={24} />
                </IconWrapper>
                <InfoWrapper>
                  <SupportText color={palette.fontExtranet} size="S">
                    {t('support.email')}
                  </SupportText>
                  <SupportText color={palette.fontPrimary} size="S">
                    {ZHILIBYLI_EMAIL}
                  </SupportText>
                </InfoWrapper>
              </Wrapper>
            </Link>
          </LinksWrapper>
        </MainInfoWrapper>
        <Instructionswrapper>
          <Row>
            <SupportText color={palette.fontExtranet} size="S">
              {t('support.instructions')}
            </SupportText>
            <KnowledgeBaseLink
              variant="primary"
              href={HOTELIERS_KNOWLEDGE_BASE || DEFAULT_HREF}
              target="_blank"
            >
              {t('support.knowledge_base')}
            </KnowledgeBaseLink>
          </Row>
          <Row>
            <SupportText color={palette.fontExtranet} size="S">
              {t('support.answer_on_questions')}
            </SupportText>
            <ChatButton onClick={handleStartChatClick}>
              {t('support.start_chat')}
            </ChatButton>
          </Row>
        </Instructionswrapper>
      </ContentWrapper>
    </StyledMenuPopup>
  );
};
